// Variables

// General

$sidebar-width: 230px;
$app-sidebar-transition: cubic-bezier(0.685, 0.0473, 0.346, 1);

// Collapsed

$sidebar-width-collapsed: 90px;
