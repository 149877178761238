//  Core

body {
  .dropdown-mega-menu-lg {
    width: 54rem;

    @include media-breakpoint-down(lg) {
      width: 30rem;
    }
  }

  .dropdown-mega-menu-md {
    width: 25rem;
  }

  .dropdown-mega-menu-sm {
    width: 20rem;
  }

  .app-header-menu {
    position: relative;
    transition: $transition-base;
    transform: scale(1);
    visibility: visible;
    transition-delay: .2s;
    margin-left: ($spacer / 2);

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
