//  Core

// Ribbon mixin

@mixin ribbon ($bgcolor1, $bgcolor2, $position: top-right) {
  > small {
    color: color-yiq($bgcolor1);
    background-color: $bgcolor1;
  }
}

// Base

.ribbon-angle {
  position: absolute;
  z-index: 8;
  width: 120px;
  height: 120px;
  overflow: hidden;
  
  & > small {
    position: absolute;
    display: block;
    width: 100%;
    padding: ($spacer / 4);
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $font-size-xs / 1.3;
  }

  &--top-right {
    top: 0;
    right: 0;

    & > small {
      transform: rotate(45deg);
      @include box-shadow(0 3px 6px -3px rgba($black, .5));
      top: 16px;
      left: 27px;
    }
  }

  &--top-left {
    top: 0;
    left: 0;

    & > small {
      transform: rotate(-45deg);
      @include box-shadow(0 3px 6px -3px rgba($black, .5));
      top: 16px;
      left: -27px;
    }
  }

  &--bottom-right {
    bottom: 0;
    right: 0;

    & > small {
      transform: rotate(-50deg);
      @include box-shadow(0 -3px 6px -3px rgba($black, .5));
      bottom: 21px;
      right: -31px;
    }
  }

  &--bottom-left {
    bottom: 0;
    left: 0;

    & > small {
      transform: rotate(50deg);
      @include box-shadow(0 -3px 6px -3px rgba($black, .5));
      bottom: 21px;
      left: -31px;
    }
  }
}

@each $color, $value in $theme-colors {
  .ribbon-#{$color} {
    @include ribbon($value, darken($value, 15%));
  }
}
